// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-acerca-de-jsx": () => import("./../../../src/pages/acerca-de.jsx" /* webpackChunkName: "component---src-pages-acerca-de-jsx" */),
  "component---src-pages-aviso-legal-jsx": () => import("./../../../src/pages/aviso-legal.jsx" /* webpackChunkName: "component---src-pages-aviso-legal-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-contrasenas-jsx": () => import("./../../../src/pages/contraseñas.jsx" /* webpackChunkName: "component---src-pages-contrasenas-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nombres-empresas-jsx": () => import("./../../../src/pages/nombres/empresas.jsx" /* webpackChunkName: "component---src-pages-nombres-empresas-jsx" */),
  "component---src-pages-nombres-fantasia-jsx": () => import("./../../../src/pages/nombres/fantasia.jsx" /* webpackChunkName: "component---src-pages-nombres-fantasia-jsx" */),
  "component---src-pages-nombres-hombre-jsx": () => import("./../../../src/pages/nombres/hombre.jsx" /* webpackChunkName: "component---src-pages-nombres-hombre-jsx" */),
  "component---src-pages-nombres-index-jsx": () => import("./../../../src/pages/nombres/index.jsx" /* webpackChunkName: "component---src-pages-nombres-index-jsx" */),
  "component---src-pages-nombres-instagram-jsx": () => import("./../../../src/pages/nombres/instagram.jsx" /* webpackChunkName: "component---src-pages-nombres-instagram-jsx" */),
  "component---src-pages-nombres-juegos-free-fire-jsx": () => import("./../../../src/pages/nombres/juegos/free-fire.jsx" /* webpackChunkName: "component---src-pages-nombres-juegos-free-fire-jsx" */),
  "component---src-pages-nombres-juegos-index-jsx": () => import("./../../../src/pages/nombres/juegos/index.jsx" /* webpackChunkName: "component---src-pages-nombres-juegos-index-jsx" */),
  "component---src-pages-nombres-mujer-jsx": () => import("./../../../src/pages/nombres/mujer.jsx" /* webpackChunkName: "component---src-pages-nombres-mujer-jsx" */),
  "component---src-pages-nombres-pais-index-jsx": () => import("./../../../src/pages/nombres/pais/index.jsx" /* webpackChunkName: "component---src-pages-nombres-pais-index-jsx" */),
  "component---src-pages-numeros-de-telefono-jsx": () => import("./../../../src/pages/numeros-de-telefono.jsx" /* webpackChunkName: "component---src-pages-numeros-de-telefono-jsx" */),
  "component---src-pages-palabras-adjetivos-jsx": () => import("./../../../src/pages/palabras/adjetivos.jsx" /* webpackChunkName: "component---src-pages-palabras-adjetivos-jsx" */),
  "component---src-pages-palabras-cada-10-segundos-jsx": () => import("./../../../src/pages/palabras/cada-10-segundos.jsx" /* webpackChunkName: "component---src-pages-palabras-cada-10-segundos-jsx" */),
  "component---src-pages-palabras-index-jsx": () => import("./../../../src/pages/palabras/index.jsx" /* webpackChunkName: "component---src-pages-palabras-index-jsx" */),
  "component---src-pages-palabras-ingles-adjetivos-jsx": () => import("./../../../src/pages/palabras/ingles/adjetivos.jsx" /* webpackChunkName: "component---src-pages-palabras-ingles-adjetivos-jsx" */),
  "component---src-pages-palabras-ingles-index-jsx": () => import("./../../../src/pages/palabras/ingles/index.jsx" /* webpackChunkName: "component---src-pages-palabras-ingles-index-jsx" */),
  "component---src-pages-palabras-ingles-sustantivos-jsx": () => import("./../../../src/pages/palabras/ingles/sustantivos.jsx" /* webpackChunkName: "component---src-pages-palabras-ingles-sustantivos-jsx" */),
  "component---src-pages-palabras-ingles-verbos-jsx": () => import("./../../../src/pages/palabras/ingles/verbos.jsx" /* webpackChunkName: "component---src-pages-palabras-ingles-verbos-jsx" */),
  "component---src-pages-palabras-inventadas-jsx": () => import("./../../../src/pages/palabras/inventadas.jsx" /* webpackChunkName: "component---src-pages-palabras-inventadas-jsx" */),
  "component---src-pages-palabras-sustantivos-jsx": () => import("./../../../src/pages/palabras/sustantivos.jsx" /* webpackChunkName: "component---src-pages-palabras-sustantivos-jsx" */),
  "component---src-pages-palabras-verbos-jsx": () => import("./../../../src/pages/palabras/verbos.jsx" /* webpackChunkName: "component---src-pages-palabras-verbos-jsx" */),
  "component---src-pages-politica-de-privacidad-jsx": () => import("./../../../src/pages/politica-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-jsx" */),
  "component---src-pages-tarjetas-de-credito-jsx": () => import("./../../../src/pages/tarjetas-de-credito.jsx" /* webpackChunkName: "component---src-pages-tarjetas-de-credito-jsx" */),
  "component---src-templates-names-of-country-jsx": () => import("./../../../src/templates/namesOfCountry.jsx" /* webpackChunkName: "component---src-templates-names-of-country-jsx" */)
}

